<template>
  <div class="container-app">
    <div class="back__button">
      <BackButton />
      <div>
        <span class="back__button__text">{{$t('returnInformationLogin')}}</span>
      </div>
    </div>
    <div class="wrapper">
      <img
        class="wrapper__logo"
        alt="Mostratec"
        src="@/assets/mostratetec.png"
      >
      <FormLogin
        @userEmail="(e) => setEmail(e)"
        @lgpdWarning="toggleWarning"
        @onLogin="onLogin"
        :lgpdChecked="lgpdChecked"
        :termModal="termModal"
        :warningModal="warningModal"
      />
      <ModalLgpd
        :modal="warningModal"
        :onClick="() => toggleModal()"
        @closeModal="() => toggleWarning()"
      />
      <ModalLgpdTerm
        @lgpdChecked="toggleChecked"
        :modal="termModal"
        :onClick="() => toggleModal()"
        :onDeny="() => logout()"
      />
    </div>
  </div>
</template>

<script>
import { FormLogin } from '@/components/organisms'
import { BackButton } from '@/components/atoms'
import { ModalLgpd, ModalLgpdTerm } from '@/components/organisms/Modals'

export default {
  name: 'Login',
  components: {
    FormLogin, BackButton, ModalLgpd, ModalLgpdTerm
  },
  data: () => ({
    termModal: false,
    warningModal: false,
    lgpdChecked: false,
    userEmail: ''
  }),
  methods: {
    toggleModal() {
      this.termModal = !this.termModal
      this.warningModal = false
    },
    toggleWarning() {
      this.warningModal = !this.warningModal
    },
    setEmail(e) {
      this.userEmail = e
    },
    async toggleChecked() {
      const response = await this.$http.patch(`/lgpd/${ this.userEmail }`, {
        term: true
      })
      if (response.status === 200) {
        this.lgpdChecked = true
        this.onLogin(localStorage.getItem('role'))
      } else {
        this.logout()
      }
    },
    onLogin(role) {
      if (role === 'feira_afiliada') {
        this.$router.push('/panel-fair-vacancies')
      }
      if (role === 'secretaria' || role === 'admin' || role === 'crc') {
        this.$router.push('/list')
      }
      if (role === 'aluno_processo_selecao') {
        this.$router.push('/panel-student-vacancies')
      }
      if (role === 'aluno_feira_afiliada') {
        this.$router.push('/panel-student-vacancies')
      }
      if (role === 'restaurante') {
        this.$router.push('/restaurant')
      }
      if (role === 'credenciamento') {
        this.$router.push('/accreditation')
      }
    },
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('role')
    }
  }
}
</script>

<style lang="scss" scoped>
$imgUrl: '../../assets/background-login.png';

.container-app {
  background-image:
    linear-gradient(
      rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05)
    ),
    url($imgUrl);
  background-size: cover;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row!important;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  padding-left: 60px;

  @media screen and (max-width: 815px) {
    flex-direction: column !important;
    justify-content: center;
    padding: 20px 15px;
  }

  @media screen and (max-width: 512px) {
    padding: 20px 0px;
  }

  .wrapper {
    align-content: center;
    background-color: #fff;
    border-radius: 50% 0px 0px 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100vh;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    width: 50%;

    @media screen and (max-width: 815px) {
      align-content: center;
      border-radius: 20px;
      clip-path: none;
      height: 100%;
      padding: 20px;
      width: 80%;
    }

    @media screen and (max-width: 512px) {
      width: 100%;
    }

    &__logo {
      margin: 0 auto 30px auto;
      max-width: 287px;
    }
  }
}

.back__button {
  display: flex;
  align-items: center;

  @media screen and (max-width: 815px) {
    order: 2;
    margin-top: 20px;
  }

  .back__button__text {
    display: flex;
    font-size: 16px;
    color: #1B778B;
    font-weight: 700;
    text-align: left;
    justify-content: flex-start;
    margin-left: 20px;
    max-width: 180px;
  }
}

</style>
