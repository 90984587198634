<template>
  <div class="wrapper">
    <card-default class="box">
      <button-default
        class="button"
        v-if="appear"
        :onClick="go"
      >
        {{ $t('requestRegistration')}}
        <img class="img" src="@/assets/add-icon.svg" alt />
      </button-default>

      <blue-information
        v-else
        class="welcome"
        :title="$t('heartyWelcome')"
        :content="$t('trackEntries')"
      />
    </card-default>
  </div>
</template>

<script>
import { BlueInformation, StudentStatusDescription } from '@/components/molecules'
import { ButtonDefault } from '@/components/atoms'
import { CardDefault } from '@/components/organisms'

export default {
  name: 'StatusLegend',
  components: {
    BlueInformation,
    StudentStatusDescription,
    ButtonDefault,
    CardDefault
  },
  data() {
    return {
      projectInfo: {},
      status: {},
      fullStatus: {},
      token: null
    }
  },
  props: {
    goTo: {
      type: String,
      required: false
    },
    appear: {
      type: Boolean,
      required: false,
      default: false
    },
    formType: {
      type: String,
      required: false
    }
  },
  computed: {
    cAppear() {
      return !!this.appear
    }
  },
  methods: {
    go() {
      this.$router.push({ path: `${ this.goTo }${ this.formType }` })
    },
    async getStatus() {
      const { data: { data } } = await this.$http.get('/status/project?statusType=projeto')
      return data
    },
    slugToID(slug) {
      const status = this.fullStatus.find((item) => item.slug === slug)
      return status?.id || null
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: flex-start;
  width: 58%;
}
.box {
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px;
  padding: 25px 0px;
  width: 100%;
}
.button {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin: 4px 15px;
  width: 265px;
}
.with_button{
  margin-left:45px
}
.with_info{
  margin-left: -175px;
}
.welcome {
  margin: 29px;
}
.img {
  margin: -4px 4px;
}
</style>
