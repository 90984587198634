import Vue from 'vue'
import VueRouter from 'vue-router'

// OBS: PARA COLOCAR UMA VALIDAÇÃO DE ACESSO A ALGUMA TELA, CASO JÁ NÃO TENHA A REGRA
// PRECISA SER IMPLEMENTADO EM src/services/aclCreate
// CASO CRIE UMA NOVA, PRECISA SER INSERIDA NA isPublic TAMBÉM, PRA NÃO PERDER ACESSOS

import {
  Home,
  About,
  Edition,
  Login,
  ListSolicitationsView,
  ProjectDetails,
  ElementaryProjectDetails,
  KindergartenProjectDetails,
  FormRequest,
  Register,
  RegisterProjectFair,
  RegisterProjectSelection,
  AccessDenied,
  PanelFairVacancies,
  PanelStudentVacancies,
  ListUsers,
  ResetPasswordView,
  GetEmailResetPasswordView,
  StartProject,
  FormSelection,
  FormViaFair,
  AdministrativePanel,
  RestaurantPanel,
  QRCodeTagPanel,
  AccreditationPanel,
  AccreditationProjectDetails
} from '@/views'

import ProjectSummaryDocument from '@/views/ProjectSummaryDocument'

import {
  AnalisisSolicitationsView,
  DetailsEdition,
  InitialHome,
  InitialProject
} from '@/components/templates'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'Home',
        component: InitialHome,
        meta: {
          rule: 'isPublic'
        }
      },
      {
        path: 'projects',
        name: 'InitialProject',
        component: InitialProject,
        meta: {
          rule: 'isPublic'
        }
      }
    ],
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/project-details-kindergarten/:id',
    name: 'KindergartenProjectDetails',
    component: KindergartenProjectDetails,
    meta: {
      rule: 'isAdminOrSecretary'
    }
  },
  {
    path: '/project-details-elementary/:id',
    name: 'ElementaryProjectDetails',
    component: ElementaryProjectDetails,
    meta: {
      rule: 'isAdminOrSecretary'
    }
  },
  {
    path: '/project-details/:id',
    name: 'ProjectDetails',
    component: ProjectDetails,
    meta: {
      rule: 'isAdminOrSecretaryOrCrc'
    }
  },
  {
    path: '/get-email',
    name: 'GetEmailResetPasswordView',
    component: GetEmailResetPasswordView,
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordView',
    component: ResetPasswordView,
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/register-project-fair',
    name: 'RegisterProjectFair',
    component: RegisterProjectFair,
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/register-project-selection',
    name: 'RegisterProjectSelection',
    component: RegisterProjectSelection,
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/edition',
    name: 'Edition',
    component: Edition,
    children: [
      {
        path: 'details',
        name: 'DetailsEdition',
        component: DetailsEdition,
        props: true,
        meta: {
          rule: 'isAdminOrSecretary'
        }
      }
    ],
    meta: {
      rule: 'isAdminOrSecretary'
    }
  },
  {
    path: '/list',
    name: 'ListSolicitations',
    component: ListSolicitationsView,
    children: [
      {
        path: 'analisis',
        name: 'AnalisisSolicitations',
        component: AnalisisSolicitationsView,
        props: true,
        meta: {
          rule: 'isAdminOrSecretaryOrCrc'
        }
      }
    ],
    meta: {
      rule: 'isAdminOrSecretaryOrCrc'
    }
  },
  {
    path: '/list-users',
    name: 'ListUsers',
    component: ListUsers,
    meta: {
      rule: 'isAdminOrSecretary'
    }
  },
  {
    path: '/formrequest',
    name: 'FormRequest',
    component: FormRequest,
    props: true,
    meta: {
      rule: 'isAffiliatedFair'
    }
  },
  {
    path: '/panel-fair-vacancies',
    name: 'PanelFairVacancies',
    component: PanelFairVacancies,
    meta: {
      rule: 'isAffiliatedFair'
    }
  },
  {
    path: '/panel-student-vacancies',
    name: 'PanelStudentVacancies',
    component: PanelStudentVacancies,
    meta: {
      rule: 'isProcessSelectionStudentAndAffiliatedFairStudent'
    }

  },
  {
    path: '/startproject/:type',
    name: 'StartProject',
    component: StartProject,
    meta: {
      rule: 'isProcessSelectionStudentAndAffiliatedFairStudent'
    }
  },
  {
    path: '/form-selection',
    name: 'FormSelection',
    component: FormSelection,
    meta: {
      rule: 'isProcessSelectionStudent'
    }
  },
  {
    path: '/form-via-fair',
    name: 'FormViaFair',
    component: FormViaFair,
    meta: {
      rule: 'isAffiliatedFairStudent'
    }
  },
  {
    path: '/administrative-panel',
    name: 'administrative-panel',
    component: AdministrativePanel,
    meta: {
      rule: 'isAdminOrSecretary'
    }
  },
  {
    path: '/access-denied',
    name: 'access-denied',
    component: AccessDenied,
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: About,
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/project-summary-document/:id',
    name: 'ProjectSummaryDocument',
    component: ProjectSummaryDocument,
    meta: {
      rule: 'isAdminOrSecretary'
    }
  },
  {
    path: '/restaurant',
    name: 'RestaurantPanel',
    component: RestaurantPanel,
    meta: {
      rule: 'isRestaurant'
    }
  },
  {
    path: '/print-etiquetas/:id/:role',
    name: 'QRCodeTagPanel',
    component: QRCodeTagPanel,
    meta: {
      rule: 'isAccreditation'
    }
  },
  {
    path: '/print-todas-etiquetas',
    name: 'QRCodeTagPanel',
    component: QRCodeTagPanel,
    meta: {
      rule: 'isAccreditation'
    }
  },
  {
    path: '/accreditation',
    name: 'AccreditationPanel',
    component: AccreditationPanel,
    meta: {
      rule: 'isAccreditation'
    }
  },
  {
    path: '/project-details-accreditation/:id',
    name: 'AccreditationProjectDetails',
    component: AccreditationProjectDetails,
    meta: {
      rule: 'isAccreditation'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
