<template>
  <header>
    <div class="header">
      <img class="logo" src="@/assets/logo.png" alt="Logo Mostratec" />
      <div class="menu">
        <div class="language border">
          <div class="menu__item" @click="openSelectLang= !openSelectLang">
            <icon-default
              class="header__icon"
              url="language.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>{{langs[this.$store.getters.lang]}}</span>
          </div>
          <template v-if="openSelectLang">
            <LangSelect />
          </template>
        </div>
        <div class="border">
          <div v-if="secretary" class="menu__item">
            <icon-default
              class="header__icon"
              url="users.svg"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <router-link to="/list-users">
                {{$t('users')}}
              </router-link>
            </span>
          </div>
        </div>

        <!-- COMENTADO POIS AINDA NÃO TEM ESSA FUNCIONALIDADE -->
        <!-- <div class="border">
          <div v-if="secretary" class="menu__item">
            <icon-default
              class="header__icon"
              url="paper-write.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <router-link to="/reports">
                <button :disabled="true">{{$t('reports')}}</button>
              </router-link>
            </span>
          </div>
        </div> -->
        <div class="border">
          <div v-if="affiliatedFair" class="menu__item">
            <icon-default
              class="header__icon"
              url="panelFairVacancies.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <router-link to="/panel-fair-vacancies">
                <button :disabled="true">{{$t('myPanelFairVacancies')}}</button>
              </router-link>
            </span>
          </div>
        </div>
        <div class="border">
          <div v-if="secretary" class="menu__item">
            <icon-default
              class="header__icon"
              url="pencil-write.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <router-link to="/list">{{$t('manageSubcriptions')}}</router-link>
            </span>
          </div>
        </div>
        <div class="border">
          <div v-if="secretary" class="menu__item">
            <icon-default
              class="header__icon"
              url="settings.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <router-link to="/edition">{{$t('fairSettings')}}</router-link>
            </span>
          </div>
        </div>
        <div class="border">
          <div v-if="secretary" class="menu__item">
            <icon-default
              class="header__icon"
              url="settings.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <router-link to="/administrative-panel">{{$t('admProjects')}}</router-link>
            </span>
          </div>
        </div>
        <!-- COMENTADO POIS AINDA NÃO TEM ESSA FUNCIONALIDADE -->
        <!-- <div class="border">
          <div v-if="secretary" class="menu__item">
            <icon-default
              class="header__icon"
              url="alarm-bell.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <router-link to="/notifications">
                <button :disabled="true">{{$t('notifications')}}</button>
              </router-link>
            </span>
          </div>
        </div> -->
        <div class="border">
          <div class="menu__item" @click="openUserOptions = !openUserOptions">
            <icon-default
              class="header__icon"
              url="profile.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <button :disabled="true">{{$t('welcome')}}</button>
            </span>
            <template v-if="openUserOptions">
              <UserOptions />
            </template>
          </div>
        </div>
      </div>
    </div>

    <keep-alive>
      <component @menuSelected="changeTabs" :is="path" />
    </keep-alive>
  </header>
</template>

<script>
import {
  IconDefault,
  EditionBreadcrumb,
  ListBreadcrumb,
  EditionDetailsBreadcrumb,
  UserConfigurationBreadcrumb,
  AdmProjectsSwitch
} from '@/components/atoms'
import LangSelect from '@/components/organisms/Headers/LangSelect'
import UserOptions from '@/components/organisms/Headers/UserOptions'

export default {
  name: 'HeaderEdition',
  data() {
    return {
      affiliatedFair:
        localStorage.getItem('role') === 'feira_afiliada',
      studentAffiliatedFair:
        localStorage.getItem('role') === 'aluno_feira_afiliada'
        || localStorage.getItem('role') === 'admin',
      secretary:
        localStorage.getItem('role') === 'secretaria'
        || localStorage.getItem('role') === 'admin',
      openSelectLang: false,
      openUserOptions: false,
      langs: {
        pt: 'pt-BR',
        en: 'en-US',
        es: 'es-ES'
      }
    }
  },
  components: {
    IconDefault,
    EditionBreadcrumb,
    ListBreadcrumb,
    EditionDetailsBreadcrumb,
    LangSelect,
    UserOptions,
    UserConfigurationBreadcrumb,
    AdmProjectsSwitch
  },
  computed: {
    path() {
      const route = this.$route.path.replace('/', '')
      if (route == 'list') return 'ListBreadcrumb'
      if (route == 'edition') return 'EditionBreadcrumb'
      if (route == 'edition/details') return 'EditionDetailsBreadcrumb'
      if (route == 'role-permissions') return 'UserConfigurationBreadcrumb'
      if (route == 'administrative-panel') return 'AdmProjectsSwitch'
    }
  },
  methods: {
    changeTabs(e) {
      this.$emit('menuSelected', e)
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  width: 100%;
}

.header {
  @include d-flex-between;
  flex-wrap: wrap;
  background: #fdfdfd;
  border-radius: 29px;
  padding: 20px 30px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1017px) {
    justify-content: center;
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .menu {
    @include d-flex-center;
    flex-wrap: wrap;
    z-index: 1;
    button {
      cursor: pointer;
    }
    .border {
      &:not(:last-of-type) {
        @media screen and (min-width: 1015px) {
          border-right: 0.7px solid $primary;
        }
      }
    }

    @media screen and (max-width: 1017px) {
      margin-top: 20px;
    }

    &__item {
      @include d-flex-center;
      cursor: pointer;
      padding-right: 15px;
      padding-left: 15px;
      position: relative;

      @media screen and (max-width: 1017px) {
        padding-bottom: 5px;
      }

      &:hover {
        opacity: 0.7;
      }

      ::v-deep .router-link-active {
        font-weight: 600;
      }

      span {
        color: $primary;
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }
  .language {
    position: relative;
  }
}
</style>
