<template>
  <div>
    <form @submit.prevent class="form">
      <input-login
        :class="[{warning: checkErrorIfContains('email')}, 'mb-6']"
        :label="$t('email')"
        id="email"
        :placeholder="$t('emailLogin')"
        type="text"
        v-model="form.email"
        @input="resetErrors"
      />
      <input-login
        :class="[{warning: checkErrorIfContains('password')}, 'mb-6']"
        :label="$t('passwordLoginLabel')"
        id="password"
        :placeholder="$t('passwordLogin')"
        type="password"
        v-model="form.password"
        @input="resetErrors"
      />
      <div class="checkbox-container">
        <input type="checkbox" id="checkbox" v-model="checked" />
        <h5 class="checkbox_text">{{$t('rememberMeLogin')}}</h5>
      </div>
      <div>
        <button-login class="button mt-7" :onClick="checkForm">{{$t('buttonLogin')}}</button-login>
      </div>
    </form>
    <p class="forgot__password">
      {{$t('forgotPassword')}}
      <a
        href="/get-email"
      >{{$t('clickHereLogin')}}</a>
      {{$t('toRecoveryLogin')}}
    </p>
    <feedback-warning
      v-if="cEmailNotConfirmed && !this.termModal && !this.warningModal"
      :text="$t('emailNotConfirmed')"
      :onClick="resetErrors"
      color="#D93025"
    />
    <feedback-warning
      v-if="cEmailPass && !this.termModal && !this.warningModal"
      :text="$t('emailOrPassword')"
      :onClick="resetErrors"
      color="#D93025"
    />
    <feedback-warning
      v-if="errors.length && !cEmailPass && !cEmailNotConfirmed && !this.termModal && !this.warningModal"
      :text="textWarning"
      :onClick="resetErrors"
      color="#D93025"
    />
    <modal-loading :modal="loading" />
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import { InputLogin, ButtonLogin, FeedbackWarning } from '@/components/atoms'
import { ModalLoading } from '@/components/organisms/Modals'

export default {
  email: 'FormLogin',
  components: {
    InputLogin,
    ButtonLogin,
    FeedbackWarning,
    ModalLoading
  },
  props: {
    lgpdChecked: {
      type: Boolean,
      required: true
    },
    termModal: {
      type: Boolean,
      required: true
    },
    warningModal: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      role: '',
      checked: true,
      loading: false,
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  computed: {
    cEmailNotConfirmed() {
      if (this.errors.length > 0) {
        return this.errors.indexOf('emailNotConfirmed') !== -1
      }
    },
    cEmailPass() {
      if (this.errors.length > 0) {
        return this.errors.indexOf('emailPassword') !== -1
      }
    }
  },
  methods: {
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    async checkForm() {
      this.resetErrors()
      for (const key in this.form) {
        if (!this.form[key]) this.errors.push(key)
      }
      if (!this.errors.length) {
        if (!this.validateEmail(this.form.email)) {
          this.textWarning = 'E-mail inválido!'
          return false
        }
        await this.loginRequest()
        this.cleanFields()
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    validateEmail(mail) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    async loginRequest() {
      try {
        this.loading = true
        const { data } = await this.$http.post('/authenticate', {
          email: this.form.email,
          password: this.form.password
        })
        const { role } = jwt_decode(data.token).data
        localStorage.setItem('token', data.token)
        localStorage.setItem('role', role)
        if (await this.checkLGPD()) {
          this.$emit('onLogin', role)
        } else {
          this.$emit('lgpdWarning')
          this.$emit('userEmail', this.form.email)
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 406) {
            return this.errors.push('emailNotConfirmed')
          }
        }
        if (error.message) {
          return this.errors.push('emailPassword')
        }
      } finally {
        this.loading = false
      }
    },
    cleanFields() {
      this.form.email = ''
      this.form.password = ''
    },
    async checkLGPD() {
      const { data } = await this.$http.get(`/lgpd?email=${ this.form.email }`)
      //console.log(data)
      return data.lgpd
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .checkbox-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .checkbox_text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $almostBlack;
    margin-bottom: 1px;
    margin-left: 5px;
  }

  .button_container {
    width: 100%;
    justify-content: center;
  }
}

.forgot__password {
  width: 180px;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: $almostBlack;
  margin: 30px auto 0pc auto;
}
</style>
