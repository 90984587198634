<template>
  <div class="edit-user">
    <div class="container">
      <div class="header">
        <h3>Editar usuário</h3>
        <div class="close" @click="close">
          <img :src="require('@/assets/close-dark.svg')" />
        </div>
      </div>
      <div class="name-user">
        {{data.name}}
      </div>
      <div class="form">
        <input-default
          :class="[{warning: checkErrorIfContains('email')}, 'mb-7']"
          label=email
          id="email"
          v-model="email"
          type="text"
          @input="resetErrors"
        />
        <div class="password">
          <input-default
            :class="[{warning: checkErrorIfContains('password')}, 'mb-7']"
            label="Senha"
            id="password"
            v-model="password"
            :type="visible"
            @input="resetErrors"
          />
          <img @click="see=!see" :src="require(`@/assets/${cSee}.svg`)" />
        </div>
        <h4>Deixe o campo vazio para manter a senha atual,
          caso queira alterar,
          só inserir uma nova senha de acordo com as informações abaixo:</h4><br/>
        <h4>
          Conter 8 caracteres,
          ao menos 1 (um) número,
          ao menos 1 (uma) letra maiúscula,
          ao menos 1 (um) caracter especial
        </h4>
        <input-select
          :class="['mb-7']"
          id="profile"
          :value= data.role
          :items="profiles"
          :label="'Perfil do usuário'"
          @input="actualRole=$event"
        />
        <input-switch
          label="Status do usuário"
          id="status"
          :defaultState="active"
          :onChange="onChangeSwitch"
          :disable="false"
        />
        <div class="buttons">
          <button class="cancel" @click="close">{{$t('back')}}</button>
          <button-default :onClick="checkForm">{{$t('save')}}</button-default>
        </div>
        <template v-if="message">
          <div class="success">{{message}}</div>
        </template>
        <feedback-warning
          v-if="errors.length"
          :text="textWarning"
          :onClick="resetErrors"
          color="#ff9800"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  InputDefault, InputCheckboxTitle, FeedbackWarning, TextWarning, InputSelect, InputSwitch
} from '@/components/atoms'
import ButtonDefault from '@/components/atoms/Buttons/ButtonDefault.vue'
import Roles from '@/utils/roles.js'
import validatePassword from '@/utils/validatePassword.js'

export default {
  name: 'FormEditUser',
  data() {
    return {
      message: '',
      see: false,
      visible: 'password',
      profiles: [],
      email: this.data.email,
      actualRole: this.data.role,
      active: this.data.active,
      password: '',
      Roles,
      interProfile: [
        { value: 'admin', text: 'Admin' },
        { value: 'secretaria', text: 'Secretaria' },
        { value: 'crc', text: 'CRC' }
      ],
      externProfile: [
        { value: 'avaliador', text: 'Avaliador' },
        { value: 'aluno_processo_selecao', text: 'Aluno processo seleção' },
        { value: 'feira_afiliada', text: 'Feira afiliada' },
        { value: 'aluno_feira_afiliada', text: 'Aluno' },
        { value: 'restaurante', text: 'Restaurante' }
      ],
      errors: [],
      modal: false
    }
  },
  components: {
    InputDefault,
    InputCheckboxTitle,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    InputSelect,
    InputSwitch
  },
  props: {
    data: Object
  },
  mounted() {
    this.roles()
  },
  methods: {
    checkForm() {
      this.resetErrors()
      for (const key in this.form) {
        if (!this.form[key]) this.errors.push(key)
      }
      if (!this.errors.length) {
        if (!this.validateEmail(this.email)) {
          this.textWarning = 'invalidEmail'
        }
        if (validatePassword(this.password) && this.password.length) {
          this.errors.push('password')
          this.textWarning = 'weakPassword'
        }
        this.saveForm()
      }
    },
    async saveForm() {
      try {
        let body = {}
        if (this.password.length > 0) {
          body = {
            // id: this.data.id,
            email: this.email,
            password: this.password,
            // language: this.lang,
            role: this.actualRole,
            // name: this.data.name,
            is_active: this.active
            // contacts: [],
          }
        } else {
          body = {
            // id: this.data.id,
            email: this.email,
            // language: this.lang,
            role: this.actualRole,
            // name: this.data.name,
            is_active: this.active
            // contacts: [],
          }
        }
        this.$emit('openModal', 'loading', true)
        const createdUser = await this.$http.patch(`/user/internal-edit/${ this.data.id }`, body)
        if (createdUser.status === 200) {
          this.message = 'sucess!'
          setTimeout(() => {
            this.message = ''
            this.close()
          }, 1000)
        }
        this.modal = true
      } catch (error) {
        if (
          error.response.data.errors[0].source.pointer
          && error.response.data.errors[0].source.pointer === 'email'
        ) {
          this.errors.push('email')
          this.textWarning = 'registrationFailedEmailAlreadyExists'
        }
        if (
          error.response.data.errors[0].source.pointer
          && error.response.data.errors[0].source.pointer === 'cpf_cnpj'
        ) {
          this.errors.push('cnpj')
          this.textWarning = this.form.country && this.form.country != 'Brazil'
            ? 'registrationFailedIcAlreadyExists'
            : 'registrationFailedCpfAlreadyExists'
        }
      }
      this.$emit('updateUser')
      this.$emit('openModal', 'loading', false)
    },
    onChangeSwitch(e) {
      this.active = e
    },
    validateEmail(mail) {
      const onlyEmail = mail.replace(/ /g, '')
      this.email = onlyEmail
      // eslint-disable-next-line
      const exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (onlyEmail.search(exp) !== -1) {
        return true
      }
      this.errors.push('email')
      return false
    },

    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    close() {
      this.$emit('close', false)
    },
    roles() {
      if ((this.data.role === 'avaliador')
        || (this.data.role === 'aluno_processo_selecao')
        || (this.data.role === 'feira_afiliada')
        || (this.data.role === 'aluno_feira_afiliada')) {
        this.profiles = this.externProfile
      } else {
        this.profiles = this.interProfile
      }
    }
  },
  computed: {
    ...mapGetters({
      lang: 'lang'
    }),
    cSee() {
      if (this.see) {
        this.visible = 'text'
        return 'visibility'
      }
      this.visible = 'password'
      return 'visibility-off'
    }
  }
}
</script>
<style lang="scss" scoped>
.edit-user {
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10;
  .container {
    max-width: 830px;
    padding: 25px 39px;
    border-radius: 20px;
    background-color: $white;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
}
.cancel {
  width: 229px;
  height: 50px;
  color: $primary;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
  outline: none;
  &:hover {
    opacity: .8;
  }
}
h4 {
  font-size: 11px;
  margin: -25px 0 20px 0;
}
.label {
  color: #303030;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  width: 100%;
}

.warning {
  color: $attention;

  .input {
    border-color: $attention;
  }

  ::v-deep fieldset {
    border-color: $attention !important;
  }
}

.input {
  background: #FFFFFF;
  border-radius: 10px;
  color: $almostBlack;
  font-weight: 500;
  max-width: 400px;
  // margin-bottom: 20px!important;
  outline: none;

  &::placeholder {
    color: #AFAEAE;
    font-size: 14px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}

::v-deep .v-text-field--outlined fieldset {
  top: 1px !important;
  bottom: 7px !important;
}

::v-deep .v-input__slot fieldset {
  color: #CDCBCB !important
}

::v-deep .v-label {
  color: #AFAEAE !important;
  font-size: 14px !important;
}
.password {
  position: relative;
  img {
    cursor: pointer;
    position: absolute;
    right: 49%;
    top: 51%;
  }
}
.name-user {
  margin-bottom: 20px;
}
.success {
  margin-top: 25px;
  background-color: $success;
  padding: 5px 8px;
  border-radius: 7px;
  color: $white;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}
</style>
