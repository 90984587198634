import axios from 'axios'
import router from '../router/index'

const url = process.env.VUE_APP_API_HOST

axios.interceptors.request.use((config) => {
  const configuration = config
  configuration.url = url + config.url

  const token = localStorage.getItem('token')
  if (token) {
    config.headers.common.Authorization = `Bearer ${ token }`
  }
  return configuration
})

axios.interceptors.response.use((response) => response, (error) => {
  if (!error.response) {
    const internalError = new Promise(() => {
    })
    return internalError
  }

  const { response: { status } } = error

  if (status === 403) {
    const notAllowed = new Promise(() => {
      router.push({ name: 'Login' })
    })
    return notAllowed
  }

  if (status === 401) {
    if (router.history.current.path !== '/') {
      const retornaLogin = new Promise(() => {
        router.push({ name: 'Login' })
      })
      return retornaLogin
    }
  }

  return Promise.reject(error)
})

export default (V) => {
  Object.defineProperties(V.prototype, {
    $http: {
      get() {
        return axios
      },
      post() {
        return axios
      },
      put() {
        return axios
      },
      patch() {
        return axios
      },
      delete() {
        return axios
      }
    }
  })
}
