<template>
  <div>
    <ModalFilterUser
      :modal="modal"
      @close="modal=$event"
      @filter="filter=$event"
    />
    <template v-if="open">
      <FormEditUser
        :data="dataEmit"
        @close="open=$event"
      />
    </template>
    <div class="table-list-users">
      <div class="info-button">
        <div>
          <div class="info">
            <img :src="require('@/assets/info-blue.png')" />
            Informações
          </div>
          <div>Vincule permissões de acesso para os usuários do sistema com base nos perfis disponíveis.</div>
        </div>
        <div v-if="cHasPermission" class="button">
          <button @click="createUser">CADASTRAR USUÁRIO</button>
        </div>
      </div>
      <div class="filter-legend">
        <div class="filter" @click="openFilter">
          <img :src="require('@/assets/filter.svg')" />
          <span>{{$t('filter')}}</span>
        </div>
        <div class="legends">
          <div>Descrição das legendas:</div>
          <div class="active">
            <img :src="require('@/assets/info-status-aceito.svg')" />
            {{$t('active')}}
          </div>
          <div class="inactive">
            <img :src="require('@/assets/info-status-default.svg')" />
            {{$t('inactive')}}
          </div>
          <div class="accepted">
            <img :src="require('@/assets/info-status-analise.svg')" />
            {{$t('accepted')}}
          </div>
          <div class="inactive">
            <img :src="require('@/assets/info-status-default.svg')" />
            {{$t('notAccepted')}}
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>{{$t('Status')}}</th>
            <th>{{$t('termLGPD')}}</th>
            <th>{{$t('name')}}</th>
            <th>{{$t('email')}}</th>
            <!-- <th>{{$t('school')}}</th> -->
            <th>{{$t('countryState')}}</th>
            <th>{{$t('profile')}}</th>
            <th v-if="cHasPermission">{{$t('edition')}}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="users.length">
            <template v-for="(user, index) in users">
              <tr v-if="!(user.id === actualUserId)" :key="user.id">
                <td>
                  <template v-if="user.active">
                    <span class="active no-img">{{$t('active')}}</span>
                  </template>
                  <template v-else>
                    <span class="inactive no-img">{{$t('inactive')}}</span>
                  </template>
                </td>
                <td>
                  <template v-if="user.lgpd_term">
                    <span class="accepted no-img">{{$t('accepted')}}</span>
                  </template>
                  <template v-else>
                    <span class="inactive no-img">{{$t('notAccept')}}</span>
                  </template>
                </td>
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td>{{countryState(index) || 'n/a'}}</td>
                <td>{{format(user.role)}}</td>
                <td
                  v-if="cHasPermission"
                  class="edit"
                  @click="openModal(true, user)"
                >
                  <img :src="require('@/assets/pencil.svg')" />
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <div class="no-result">
              {{$t('noResults')}}
            </div>
          </template>
        </tbody>
      </table>
      <div class="pagination">
        <v-container>
          <v-row justify="end">
            <v-pagination v-model="page" class="my-4" :length="lastPage"></v-pagination>
          </v-row>
          <v-row class="mr-0" justify="end">
            <span
              class="pagination__text"
            >{{page == 1 ? '1' : ((page - 1) * perPage) + 1}} - {{page * perPage}} de {{total}}</span>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import Roles from '@/utils/roles.js'
import { ModalFilterUser } from '@/components/organisms/Modals'
import { FormEditUser } from '@/components/organisms/Forms'

export default {
  name: 'TableListUsers',
  data() {
    return {
      dataEmit: {},
      open: false,
      users: [],
      lastPage: 0,
      page: 1,
      perPage: 0,
      total: 0,
      modal: false,
      filter: {},
      actualRole: '',
      actualUserId: ''
    }
  },
  props: {
    index: Number
  },
  components: {
    FormEditUser,
    ModalFilterUser
  },
  mounted() {
    this.getUsersWithFilter()
    this.getRole()
    this.getUser()
  },
  watch: {
    filter() {
      this.page = 1
      this.getUsersWithFilter()
    },
    page() {
      this.getUsersWithFilter()
    }
  },
  methods: {
    getRole() {
      this.actualRole = localStorage.getItem('role')
    },
    getUser() {
      const data = jwt_decode(localStorage.getItem('token'))
      this.actualUserId = data.uid
    },
    openFilter() {
      this.filter = {}
      this.modal = true
    },
    createUser() {
      this.$emit('newIndex', 1)
    },
    openModal(open, data) {
      this.open = open
      this.dataEmit = data
    },
    async getUsersWithFilter() {
      try {
        for (const i in this.filter) {
          if (this.filter[i] === '') {
            delete this.filter[i]
          }
        }
        const { data: response } = await this.$http.post(`/user/search/all/?page=${ this.page }`, this.filter)
        this.users = response.data
        this.lastPage = response.lastPage
        this.page = response.page
        this.perPage = response.perPage
        this.total = response.total
        this.$forceUpdate()
      } catch (error) {
        console.error(error)
      }
    },
    format(text) {
      return Roles[text]
    },

    countryState(index) {
      if (this.users.length > 0) {
        if (this.users[index].pre_registration_fairs) {
          return `${ this.users[index].pre_registration_fairs.country }
            /${ this.users[index].pre_registration_fairs.state }`
        }
        if (this.users[index].pre_registration_student_fair_affiliates) {
          return `${ this.users[index].pre_registration_student_fair_affiliates.country }
          /${ this.users[index].pre_registration_student_fair_affiliates.state }`
        }
        if (this.users[index].pre_registration_student_process_selections) {
          return `${ this.users[index].pre_registration_student_process_selections.country }
          /${ this.users[index].pre_registration_student_process_selections.state }`
        }
        if (this.users[index].pre_registration_internal_users) {
          return `${ this.users[index].pre_registration_internal_users.country }
          /${ this.users[index].pre_registration_internal_users.state }`
        }
      }
    }
  },
  computed: {
    cHasPermission() {
      return this.actualRole === 'admin'
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .filter {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 40px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    img {
      margin-right: 5px;
    }
    span {
      font-family: "Mulish", sans-serif;
      font-weight: 600;
      font-size: 16px;
      color: $primary;
    }
  }
  .legends {
    display: flex;
    align-items: center;
    div {
      margin-left: 15px;
    }
  }
}
.table-list-users {
  min-width: 100%;
  border-radius: 20px;
  padding: 24px 13px;
  margin-bottom: 24px;
  background-color: $white;
  font-family: "Mulish", sans-serif;
  .info-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    button {
      background-color: $primary;
      width: 274px;
      height: 40px;
      border-radius: 10px;
      color: $white;
      font-weight: 900;
      margin-top: 5px;
      transition: 0.2s ease-in-out;
      outline: none;
      &:hover {
        opacity: 0.8;
      }
    }
    .info {
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
  }
}
table {
  border-collapse: collapse;
  min-width: 971px;
  width: 100%;
  overflow-x: scroll!important;
  thead th{
    text-align: left;
    padding-left: 15px;
    &:first-child {
      border-radius: 10px 0 0 0;
    }
    &:last-child {
      border-radius: 0 10px 0 0;
    }
    &:nth-child(1) {
      text-align: center;
      width: 140px;
    }
    &:nth-child(2) {
      width: 120px;
    }
    &:nth-child(3) {
      width: 250px;
    }
    &:nth-child(4) {
      width: 230px;
    }
    &:nth-child(5) {
      width: 150px;
    }
    &:nth-child(6) {
      width: 120px;
    }
    &:nth-child(7) {
      width: 120px;
    }
    &:nth-child(8) {
      text-align: center;
      width: 120px;
    }
  }
  thead tr {
    background-color:#DBE9EC;
  }
  tr {
    height: 60px;
  }
  tbody tr td{
    padding-left: 15px;
    word-wrap: break-word!important;
    &:nth-child(1) {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:nth-child(1) {
      text-align: center;
      width: 140px;
    }
    &:nth-child(2) {
      width: 120px;
    }
    &:nth-child(3) {
      width: 250px;
    }
    &:nth-child(4) {
      word-wrap: break-word;
      width: 130px;
    }
    &:nth-child(5) {
      width: 150px;
    }
    &:nth-child(6) {
      width: 120px;
    }
    &:nth-child(7) {
      width: 120px;
    }
    &:nth-child(8) {
      text-align: center;
      width: 120px;
    }
  }
  tr {
    &:nth-child(even) {
      background-color: #F6F6F6;
    }
  }
}
.active {
  max-width: 100px;
  color: #046619;
  background-color: #33B850;
}
.inactive {
  // width: 125px!important;
  white-space: nowrap;
  background-color: #ECECEC;
  color: #303030;
  img {
    margin-right: 5px;
  }
  &.no-img {
    justify-content: center;
    width: auto!important;
  }
}
.accepted {
  // max-width: 110px;
  color: #0E5671;
  background-color: #319CB4;
  display: flex;
  padding: 5px 10px;
  border-radius: 50px;
  justify-content: center;
  img {
    margin-right: 5px;
  }
  &.no-img {
    justify-content: center;
    width: 85px;
  }
}
.active, .inactive {
  display: flex;
  padding: 5px 10px;
  border-radius: 50px;
  &.no-img {
    justify-content: center;
    width: 65px;
  }
  img {
    margin-right: 5px;
  }
}
.edit {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}
.no-result {
  font-size: 24px;
}
</style>
