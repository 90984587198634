<template>
  <div>
    <svg style="display: none;">
    <symbol id="editIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 528.899 528.899">
      <g>
        <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
          c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
          C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
          L27.473,390.597L0.3,512.69z"/>
      </g>
    </symbol>
  </svg>    
    <div class="container-content">
      <div class="container-header">
        <p class="size-name">{{ this.$t("enrollment") }}</p>
        <p class="size-rate">{{ this.$t("value") }}</p>
        <p class="size-meal">{{ this.$t("food") }}</p>
        <p class="size-final">{{ this.$t("finalValue") }}</p>
        <p class="size-attend">{{ "Compareceu" }}</p>
        <p class="size-printer">{{ "Etiqueta" }}</p>
      </div>

      <!-- Modal para edição dos nomes -->
      <div v-if="showEditModal" class="modal" id="editBox" ref="editedNameBox">
        <div class="modal-content" style="background-color: antiquewhite; border: 1px solid gray;padding:10px;">
          <h3>Editar Nome</h3>
          <!-- p>{{ selectedItem.full_name }}</p -->
          <input v-model="editedName" placeholder="Digite o novo nome" class="inputName" id="editedNameInput" ref="editedNameInput" style="background-color: #FFF;width:500px;" />
          <button @click="saveEdit" class="saveButton">Salvar</button>
          <button @click="closeEditModal" class="cancelButton">Cancelar</button>
        </div>
      </div>      
      <label id="info" ref="info" class="vinfo"  v-text="infoText" >AAAA</label>

      <p class="subtitle">{{ this.$t("students") }}</p>
      <div
        class="container-info"
        v-for="(item, index) in studentsList"
        :key="'student' + index"
      >
        <div class="size-name">
            <div style="display:inline-block;vertical-align:left;">
              <label :for="`checkboxStudent${index}`">
                <input
                  :id="`checkboxStudent${index}`"
                  v-model="item.will_pay"
                  type="checkbox"
                  @change="validateAdvisorsCheckbox(item)"
                  :disabled="true"
                />
                  {{ item.full_name }} 
                  <button @click="openEditModal(item, 'students')" class="editNameButton">
                    <svg width="15px" height="15px" fill="#578dd2">
                    <use href="#editIcon" />
                    </svg>
                  </button>
              </label>
            </div>
            <div style="display:inline-block;vertical-align:left;">
              <label style="margin-left:40px;color:brown;">
                {{ $t(item.t_shirt) }}
              </label>
            </div>

        </div>

        <div class="size-rate" v-if="schoolType !== 'foreign'">
          <p v-if="item.will_pay" class="color-text">
            R${{ valueRate.toFixed(2).replace(/\./g, ",") }}
          </p>
          <p v-else class="color-text">R$0,00</p>
        </div>

        <div class="size-rate" v-if="schoolType === 'foreign'">
          <p v-if="item.will_pay" class="color-text">
            US${{ valueRate.toFixed(2) }}
          </p>
          <p v-else class="color-text">US$0.00</p>
        </div>

        <div class="size-meal">
          <input
            class="input-meal"
            text="text"
            v-model="studentsList[index].meal"
            @input="
              () =>
                calculateFinalValue(
                  studentsList[index].meal,
                  index,
                  studentsList[index].type
                )
            "
            disabled
          />
        </div>

        <div v-if="schoolType !== 'foreign'">
          <p v-if="item.will_pay" class="color-text size-final">
            R${{
              studentsList[index].finalValue.toFixed(2).replace(/\./g, ",")
            }}
          </p>
          <p v-else class="color-text size-final">R$0,00</p>
        </div>

        <div v-if="schoolType === 'foreign'">
          <p v-if="item.will_pay" class="color-text size-final">
            US${{ studentsList[index].finalValue.toFixed(2) }}
          </p>
          <p v-else class="color-text size-final">US$0.00</p>
        </div>

        <div class="size-attend">
          <input
            :id="`checkboxStudent${index}`"
            v-model="item.appeared"
            type="checkbox"
          />
        </div>

        <div class="size-printer">
          <button-default
            :width="'100%'"
            :disabled="disableButton"
            :onClick="() => printer(item.id)"
          >
            {{ "Imprimir" }}
          </button-default>
        </div>

        <div class="hidden">
          <div :id="item.id" class="qrcodeContent">
            <div class="left-side-print">
              <p><strong> {{ newName(item.full_name) }} </strong></p>
              <p>{{ item.city }}/{{ item.state }}</p>
              <p>{{ item.country }}</p>
              <p>Estande: {{ projectInfo.booth }}</p>
            </div>
            <div class="right-side-print">
              <qrcode-vue v-if="!item.type" :value="`${item.id}-s`" :size="100" level="H" />
              <qrcode-vue v-if="item.type" :value="`${item.id}-i`" :size="100" level="H" />
            </div>
          </div>
        </div>
      </div>

      <p class="subtitle advisors">{{ this.$t("advisor") }}</p>

      <div
        class="container-info"
        v-for="(item, index) in nameAdvisor"
        :key="'advisor' + index"
      >
      
        <div class="size-name advisors">
          <div style="display:inline-block;vertical-align:left;">
            <label :for="`checkboxAdvisor${index}`">
              <input
                :id="`checkboxAdvisor${index}`"
                v-model="item.will_pay"
                type="checkbox"
                @change="check(item, index)"
                :disabled="true"
              />
              {{ item.full_name }} 
              <button @click="openEditModal(item, 'involveds')" class="editNameButton">
                <svg width="15px" height="15px" fill="#578dd2">
                    <use href="#editIcon" />
                    </svg>

              </button>
            </label>
          </div>
            <div style="display:inline-block;vertical-align:left;">
              <label style="margin-left:40px;color:brown;">
                {{ $t(item.t_shirt) }}
              </label>
            </div>

        </div>

        <div class="size-rate" v-if="schoolType !== 'foreign'">
          <p v-if="item.will_pay" class="color-text">
            R${{ valueRate.toFixed(2).replace(/\./g, ",") }}
          </p>
          <p v-else class="color-text">R$0,00</p>
        </div>

        <div class="size-rate" v-if="schoolType === 'foreign'">
          <p v-if="item.will_pay" class="color-text">
            US${{ valueRate.toFixed(2) }}
          </p>
          <p v-else class="color-text">US$0,00</p>
        </div>

        <div class="size-meal">
          <input
            class="input-meal"
            text="text"
            v-model="nameAdvisor[index].meal"
            @input="
              () =>
                calculateFinalValue(
                  nameAdvisor[index].meal,
                  index,
                  nameAdvisor[index].type
                )
            "
            disabled
          />
        </div>

        <div v-if="schoolType !== 'foreign'">
          <p v-if="item.will_pay" class="color-text size-final">
            R${{ nameAdvisor[index].finalValue.toFixed(2).replace(/\./g, ",") }}
          </p>
          <p v-else class="color-text size-final">R$0,00</p>
        </div>

        <div v-if="schoolType === 'foreign'">
          <p v-if="item.will_pay" class="color-text size-final">
            US${{ nameAdvisor[index].finalValue.toFixed(2) }}
          </p>
          <p v-else class="color-text size-final">US$0,00</p>
        </div>

        <div class="size-attend">
          <input
            :id="`checkboxStudent${index}`"
            v-model="item.appeared"
            type="checkbox"
          />
        </div>

        <div class="size-printer">
          <button-default
            :width="'100%'"
            :disabled="disableButton"
            :onClick="() => printer(item.id)"
            >{{ "Imprimir" }}</button-default
          >
        </div>
         <div class="hidden">
          <div :id="item.id" class="qrcodeContent">
            <div class="left-side-print">
              <p>{{ newName(item.full_name) }}</p>
              <p>{{ item.city }}/{{ item.state }}</p>
              <p>{{ item.country }}</p>
              <p>Estande: {{ projectInfo.booth }}</p>
            </div>
            <div class="right-side-print">
              <qrcode-vue v-if="!item.type" :value="`${item.id}-s`" :size="100" level="H" />
              <qrcode-vue v-if="item.type" :value="`${item.id}-i`" :size="100" level="H" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="coadvisorList.length">
        <p class="subtitle advisors">{{ this.$t("coAdvisor") }}</p>

        <div
          class="container-info"
          v-for="(item, index) in coadvisorList"
          :key="'coadvisor' + index"
        >

          <div class="size-name">
            <div style="display:inline-block;vertical-align:left;">
              <label :for="`checkboxCoadvisor${index}`">
                <input
                  :id="`checkboxCoadvisor${index}`"
                  v-model="item.will_pay"
                  type="checkbox"
                  @input="check"
                  @change="check(item, index)"
                  :disabled="true"
                />
                {{ item.full_name }} 
                <button @click="openEditModal(item, 'involveds')" class="editNameButton">
                  <svg width="15px" height="15px" fill="#578dd2">
                    <use href="#editIcon" />
                    </svg>
                </button>
              </label>
            </div>
            <div style="display:inline-block;vertical-align:left;">
              <label style="margin-left:40px;color:brown;">
                {{ $t(item.t_shirt) }}
              </label>
            </div>
          </div>

          <div class="size-rate" v-if="schoolType !== 'foreign'">
            <p v-if="item.will_pay" class="color-text">
              R${{ valueRate.toFixed(2).replace(/\./g, ",") }}
            </p>
            <p v-else class="color-text">R$0,00</p>
          </div>
          <div class="size-rate" v-if="schoolType === 'foreign'">
            <p v-if="item.will_pay" class="color-text">
              US${{ valueRate.toFixed(2) }}
            </p>
            <p v-else class="color-text">US$0,00</p>
          </div>

          <div class="size-meal">
            <input
              class="input-meal"
              text="text"
              v-model="coadvisorList[index].meal"
              @input="
                () =>
                  calculateFinalValue(
                    coadvisorList[index].meal,
                    index,
                    coadvisorList[index].type
                  )
              "
              disabled
            />
          </div>

          <div v-if="schoolType !== 'foreign'">
            <p v-if="item.will_pay" class="color-text size-final">
              R${{
                coadvisorList[index].finalValue.toFixed(2).replace(/\./g, ",")
              }}
            </p>
            <p v-else class="color-text size-final">R$0,00</p>
          </div>

          <div v-if="schoolType === 'foreign'">
            <p v-if="item.will_pay" class="color-text size-final">
              US${{ coadvisorList[index].finalValue.toFixed(2) }}
            </p>
            <p v-else class="color-text size-final">US$0,00</p>
          </div>

          <div class="size-attend">
            <input
              :id="`checkboxStudent${index}`"
              v-model="item.appeared"
              type="checkbox"
            />
          </div>

          <div class="size-printer">
            <button-default
              :width="'100%'"
              :disabled="disableButton"
              :onClick="() => printer(item.id)"
              >{{ "Imprimir" }}</button-default
            >
          </div>
          <div class="hidden">
          <div :id="item.id" class="qrcodeContent">
            <div class="left-side-print">
              <p>{{ newName(item.full_name) }}</p>
              <p>{{ item.city }}/{{ item.state }}</p>
              <p>{{ item.country }}</p>
              <p>Estande: {{ projectInfo.booth }}</p>
            </div>
            <div class="right-side-print">
              <qrcode-vue v-if="!item.type" :value="`${item.id}-s`" :size="100" level="H" />
              <qrcode-vue v-if="item.type" :value="`${item.id}-i`" :size="100" level="H" />
            </div>
          </div>
        </div>
        </div>
      </div>

      <div class="container-values">
        <div class="values-finals">
          <div
            class="total-payment subtotal-payment"
            v-if="schoolType !== 'foreign'"
          >
            <p><strong>SUBTOTAL</strong></p>
            <p class="color-text">
              R${{ subtotal.toFixed(2).replace(/\./g, ",") }}
            </p>
          </div>

          <div
            class="total-payment subtotal-payment"
            v-if="schoolType === 'foreign'"
          >
            <p><strong>SUBTOTAL</strong></p>
            <p class="color-text">US${{ subtotal.toFixed(2) }}</p>
          </div>

          <div class="discount-container">
            <div class="discount-content">
              <p>
                <strong>Desconto: </strong>
              </p>
              <currency-input
                class="currency-input"
                placeholder="0"
                @input="verifyTotal()"
                type="text"
                v-model="discount"
                :options="{
                  currency: 'BRL',
                  currencyDisplay: 'hidden',
                  autoDecimalDigits: true,
                }"
                :disabled="true"
              />
            </div>
          </div>

          <div class="total-payment" v-if="schoolType !== 'foreign'">
            <p><strong>TOTAL</strong></p>
            <p class="color-text">
              R${{ total.toFixed(2).replace(/\./g, ",") }}
            </p>
          </div>

          <div class="total-payment" v-if="schoolType === 'foreign'">
            <p><strong>TOTAL</strong></p>
            <p class="color-text">US${{ total.toFixed(2) }}</p>
          </div>
        </div>

        <div class="container-others">
          <p class="subtitle">
            {{ "Pagamento" }}
          </p>

          <p class="subtitle">
            {{ `Forma de Pagamento:  ${paymentType(typePayment)}` }}
          </p>

          <div class="checkbox-payment">
            <p class="status-style">
              {{ `Status:` }}
            </p>

            <label :for="`checkBoxPayment`">
              <input
                :disabled="typePayment === 'credenciamento' ? false : true"
                :id="`checkBoxPayment`"
                v-model="checkBoxPayment"
                type="checkbox"
                class="input-payment-confirm"
              />
              {{ "Confirmar Pagamento" }}
            </label>
          </div>



          <input-text-area
            :allText="observations"
            v-model="observations"
            :placeholder="'Informações extras'"
            :textMax="2000"
            label="Observações:"
          >
          </input-text-area>
        </div>
      </div>


      <div class="button-save">
        <p class="status-style">
              Informe o nome do atendente
            </p>
        <input-default
          :width="200"
          v-model="atendente"
          :disabled="atendenteDisabled"
          style="margin-right:10px;"
          >
        </input-default>
        <button-default 
          :width="'12%'"
          :disabled="disableButton"
          :onClick="checkForm"
          >{{ $t("finish") }}</button-default
        >
      </div>
    </div>

    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>
<script>
import axios from 'axios'
import printJS from 'print-js'
import QrcodeVue from 'qrcode.vue'
import {
  ButtonDefault,
  FeedbackWarning
} from '@/components/atoms'
import CurrencyInput from '../components/atoms/Inputs/CurrencyInput'
import InputTextArea from '../components/atoms/Inputs/InputTextArea'
import InputDefault  from '../components/atoms/Inputs/InputDefault'

export default {
  name: 'FormAccreditationPayment',
  components: {
    ButtonDefault,
    FeedbackWarning,
    CurrencyInput,
    InputTextArea,
    InputDefault,
    QrcodeVue
  },
  data() {
    return {
      showEditModal: false,
      infoText: '', // Initial text displayed in the label
      selectedCategory: '',
      optionAdvisor: '',
      projectId: '',
      levelEducation: '',
      schoolType: '',
      studentsList: [],
      nameAdvisor: [],
      coadvisorList: [],
      checked: false,
      valueRate: 0,
      finalValue: 0,
      mealOption: 0,
      feesList: [],
      typePayment: '',
      discount: Number(this.projectInfo.discount),
      isChange: false,
      value: undefined,
      paymentOptions: [
        { label: 'Mercado Pago', value: 'mercado pago' },
        {
          label: 'Nota de empenho (somente prefeituras)',
          value: 'nota empenho'
        },
        { label: this.$t('payOnAccreditation'), value: 'credenciamento' }
      ],
      schoolTypeOptions: [
        { text: this.$t('public'), value: 'public' },
        { text: this.$t('private'), value: 'private' },
        { text: this.$t('foreign'), value: 'foreign' }
      ],
      // Alterações chamado 463
      filterMealOptions: [
        { text: this.$t('noMeal'), value: 0 },
        { text: this.$t('oneMeals'), value: 1 },
        { text: this.$t('twoMeals'), value: 2 },
        { text: this.$t('threeMeals'), value: 3 },
        { text: this.$t('fourMeals'), value: 4 },
        { text: this.$t('fiveMeals'), value: 5 },
        { text: this.$t('sixMeals'), value: 6 },
        { text: this.$t('sevenMeals'), value: 7 }
      ],
      advisorOptions: [
        { text: 'Cobrar taxa de participação', value: 'yes' },
        { text: 'Não cobrar taxa de participação', value: 'no' }
      ],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      disableButton: false,
      checkBoxPayment: false,
      observations: '',
      atendente: '',
      atendenteDisabled: false
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  created() {
    this.getData()
  },
  methods: {
    openEditModal(item, category) {
      this.infoText = ""; // Update the label text
      this.closeEditModal();
      this.selectedItem = item;
      this.editedName = item.full_name;
      this.selectedCategory = category;
      this.showEditModal = true;
      // Focus on the input element
      this.$refs.editedNameBox.scrollIntoView({ behavior: 'smooth' });
      this.$refs.editedNameInput.focus();
    },
    closeEditModal() {
      this.showEditModal = false;
      this.selectedItem = null;
      this.editedName = '';
      this.selectedCategory = '';
    },
    async saveEdit() {
      try {
        console.log(' this.selectedItem.id' ,  this.selectedItem.id)
        console.log(' this.editedName' ,  this.editedName)
        const response = await this.$http.post('/check-in/update-name/', {
          id: this.selectedItem.id,
          full_name: this.editedName,
          category: this.selectedCategory
        });
        /*
        const response = await axios.post('/api/update-name', {
          id: this.selectedItem.id,
          full_name: this.editedName,
          category: this.selectedCategory
        });
        */
        if (response.data.success) {
          this.selectedItem.full_name = this.editedName;
          //alert(response.data.message);
          this.infoText = response.data.message; // Update the label text
        } else {
          //alert(response.data.message);
          this.infoText = response.data.message; // Update the label text
        }
      } catch (error) {
        console.error(error);
        alert('Ocorreu um erro ao tentar alterar o nome.');
      } finally {
        this.closeEditModal();
      }
    },
    printer(id) {
      printJS({
        printable: `${ id }`,
        type: 'html',
        targetStyles: ['*']
      })
    },
    async getData() {
      await this.getFees()
      const getId = window.location.href.split('/').pop()
      const { data: response } = await this.$http.get(
        `/payment-details/${ getId }`
      )
      console.log('response',response)
      this.levelEducation = this.projectInfo.level
      this.studentsList = this.projectInfo.students
      console.log('this.studentsList',this.studentsList)
      this.schoolType = (response.foreign ? 'foreign' : response.school_type)
      console.log('this.schoolType',this.schoolType)
      this.checkBoxPayment = this.projectInfo.payment_approved
      this.typePayment = this.verifyPaymentType(this.projectInfo.payment_type)
      this.discount = response.discount
      this.observations = this.projectInfo.observation_accreditation
      this.atendente = (this.projectInfo.atendente ? this.projectInfo.atendente.trim() : '')
      this.atendenteDisabled = (this.atendente.length > 2 ? true : false)
      this.projectInfo.involveds.map(
        (involved) => {
          if (involved.type === 'orientador') {
            this.nameAdvisor = [...this.nameAdvisor, { ...involved, isParticipant: 'yes' }]
          }

          if (involved.type === 'coorientador') {
            this.coadvisorList = [...this.coadvisorList, { ...involved, isParticipant: 'yes' }]
          }

          return false
        }
      )
      // console.log('1-response.foreign='+(response.foreign?'true':'false'))
      // console.log('2-this.schoolType='+this.schoolType)
      this.calculateFee(this.schoolType)
      this.calculeForFirstTime()
    },
    verifyPaymentType(payType){
      if (payType=='nota empenho'){
        this.checkBoxPayment = true
      }
      return payType

    },
    calculateFee(schoolType) {
      const {
        //national_child_education: nationalChildEdu,
        //national_public_middle: nationalPublicMid,
        //national_public_high: nationalPublicHighSchool,
        //national_private_middle: nationalPrivateMid,
        //national_private_high: nationalPrivateHighSchool,
        //foreign_middle: foreignMid,
        //foreign_high: foreignHighSchool
        national_public_child: nationalPublicCh,
        national_public_middle: nationalPublicMid,
        national_public_middle_cat_1: nationalPublicMidC1,
        national_public_middle_cat_2: nationalPublicMidC2,
        national_public_middle_cat_3: nationalPublicMidC3,
        national_public_middle_cat_eja: nationalPublicMidCEja,
        national_public_high: nationalPublicHighSchool,
        national_private_child: nationalPrivateCh,
        national_private_middle: nationalPrivateMid,
        national_private_middle_cat_1: nationalPrivateMidC1,
        national_private_middle_cat_2: nationalPrivateMidC2,
        national_private_middle_cat_3: nationalPrivateMidC3,
        national_private_middle_cat_eja: nationalPrivateMidCEja,
        national_private_high: nationalPrivateHighSchool,
        foreign_middle: foreignMid,
        foreign_middle_cat_1: foreignMidC1,
        foreign_middle_cat_2: foreignMidC2,
        foreign_middle_cat_3: foreignMidC3,
        foreign_middle_cat_eja: foreignMidCEja,
        foreign_high: foreignHighSchool        
        
      } = this.feesList[0]
      // console.log('this.feesList[0]=\/')
      // console.log(this.feesList[0])
      // console.log(foreignHighSchool)
      //const nationalChildEducation = nationalChildEdu
      //const nationalPublicMiddle = nationalPublicMid
      //const nationalPublicHigh = nationalPublicHighSchool
      //const nationalPrivateMiddle = nationalPrivateMid
      //const nationalPrivateHigh = nationalPrivateHighSchool
      //const foreignMiddle = foreignMid
      //const foreignHigh = foreignHighSchool
      const nationalPublicChild = nationalPublicCh
      const nationalPublicMiddle = nationalPublicMid
      const nationalPublicMiddleCat1 = nationalPublicMidC1
      const nationalPublicMiddleCat2 = nationalPublicMidC2
      const nationalPublicMiddleCat3 = nationalPublicMidC3
      const nationalPublicMiddleCatEja = nationalPublicMidCEja
      const nationalPublicHigh = nationalPublicHighSchool
      const nationalPrivateChild = nationalPrivateCh
      const nationalPrivateMiddle = nationalPrivateMid
      const nationalPrivateMiddleCat1 = nationalPrivateMidC1
      const nationalPrivateMiddleCat2 = nationalPrivateMidC2
      const nationalPrivateMiddleCat3 = nationalPrivateMidC3
      const nationalPrivateMiddleCatEja = nationalPrivateMidCEja
      const nationalPrivateHigh = nationalPrivateHighSchool
      const foreignMiddle = foreignMid
      const foreignMiddleCat1 = foreignMidC1
      const foreignMiddleCat2 = foreignMidC2
      const foreignMiddleCat3 = foreignMidC3
      const foreignMiddleCatEja = foreignMidCEja
      const foreignHigh = foreignHighSchool

      // console.log(foreignHigh)
      this.typeSchool = schoolType
      // console.log('3a-schoolType='+this.schoolType)
      // console.log('3b-this.typeSchool='+this.typeSchool)
      // console.log('4-this.levelEducation '+this.levelEducation)
      if (this.levelEducation === 'grade') {
        this.valueRate = nationalChildEducation
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle') {
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddle,
          private: nationalPrivateMiddle,
          foreign: foreignMiddle
        }
        // console.log('5mid-schoolType='+this.schoolType)
        this.valueRate = valueFeeByTypeSchool[this.schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(this.schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(this.schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(this.schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle_cat_1') {
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddleCat1,
          private: nationalPrivateMiddleCat1,
          foreign: foreignMiddleCat1
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle_cat_2') {
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddleCat2,
          private: nationalPrivateMiddleCat2,
          foreign: foreignMiddleCat2
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle_cat_3') {
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddleCat3,
          private: nationalPrivateMiddleCat3,
          foreign: foreignMiddleCat3
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle_cat_eja') {
        //console.log('entrou em middle_cat_eja')
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddleCatEja,
          private: nationalPrivateMiddleCatEja,
          foreign: foreignMiddleCatEja
        }
        //console.log('valueFeeByTypeSchool',valueFeeByTypeSchool)
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'high') {
        const valueFeeByTypeSchool = {
          public: nationalPublicHigh,
          private: nationalPrivateHigh,
          foreign: foreignHigh
        }
        // console.log(valueFeeByTypeSchool)  console.log('5-high-schoolType='+schoolType)  console.log('5-high-this.schoolType='+this.schoolType)
        this.valueRate = valueFeeByTypeSchool[this.schoolType]
        // console.log('5-this.valueRate '+this.valueRate)
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(this.schoolType, student.meal) : student.finalValue = 0
          // console.log('student.finalValue '+student.finalValue)
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(this.schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(this.schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
    },
    calculateFinalValue(mealOption, index, role) {
      const { national_meal: nationalMeal, foreign_meal: foreignMeal } = this.feesList[0]
      // console.log('this.valueRate ' + this.valueRate)
      /*
      if (!this.valueRate) {
        this.mealOption = 0
        alert('calculateFinalValue')
        return false
      }
      */
      if (mealOption === 0) {
        this.mealOption = 0
        this.finalValue = this.valueRate
      }
      // Alteração chamado 463
      if ((mealOption >= 1) && (mealOption <= 7)) {
        if (this.typeSchool !== 'foreign') {
          this.mealOption = nationalMeal * mealOption
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreignMeal * mealOption
          this.finalValue = this.valueRate + this.mealOption
        }
      }
      /*
      if (mealOption === 6) {
        if (this.typeSchool !== 'foreign') {
          this.mealOption = nationalMeal * 6
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreignMeal * 6
          this.finalValue = this.valueRate + this.mealOption
        }
      }
      if (mealOption === 7) {
        if (this.typeSchool !== 'foreign') {
          this.mealOption = nationalMeal * 7
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreignMeal * 7
          this.finalValue = this.valueRate + this.mealOption
        }
      }
      */
      if (!role) {
        this.studentsList[index].finalValue = this.finalValue
        this.studentsList[index].mealValue = this.mealOption
      }
      if (role === 'orientador') {
        this.nameAdvisor[index].finalValue = this.finalValue
        this.nameAdvisor[index].mealValue = this.mealOption
      }
      if (role === 'coorientador') {
        this.coadvisorList[index].finalValue = this.finalValue
        this.coadvisorList[index].mealValue = this.mealOption
      }
      return this.finalValue
    },
    calculateMeal(schoolType, meal) {
      const { national_meal: nationalMeal, foreign_meal: foreignMeal } = this.feesList[0]
      let mealValue = 0
      mealValue = this.valueRate
      if (schoolType !== 'foreign' && meal > 0) {
        mealValue += meal * nationalMeal
      }
      if (schoolType === 'foreign' && meal > 0) {
        mealValue += meal * foreignMeal
      }
      return mealValue
    },
    checkWillPay(option, index, role) {
      if (role === 'orientador') {
        this.nameAdvisor[index].will_pay = option === 'yes'
      }
      if (role === 'coorientador') {
        this.coadvisorList[index].will_pay = option === 'yes'
      }
    },
    async getFees() {
      try {
        const response = await this.$http.get('/fees')
        this.feesList = response.data
      } catch (error) {
        console.log('erro:', error)
      }
    },
    async checkForm() {
      await this.savePayment()
      return window.location.reload()
    },
    check(item, index) {
      if (item.will_pay) {
        if (item.role === 'orientador') {
          this.nameAdvisor[index].isParticipant = 'yes'
        }
        if (item.role === 'coorientador') {
          this.coadvisorList[index].isParticipant = 'yes'
        }
      }
      if (!item.will_pay) {
        this.validateAdvisorsCheckbox(item)
        if (item.role === 'orientador') {
          this.nameAdvisor[index].isParticipant = ''
        }
        if (item.role === 'coorientador') {
          this.coadvisorList[index].isParticipant = ''
        }
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    getStudentsAndInvolveds() {
      const students = this.studentsList.map(({ id, appeared }) => ({
        id,
        appeared
      }))
      const involveds = this.nameAdvisor.map(({ id, appeared }) => ({
        id,
        appeared
      }))
      const coInvolveds = this.coadvisorList.map(({ id, appeared }) => ({
        id,
        appeared
      }))
      return { students, involveds, coInvolveds }
    },
    async savePayment() {
      const { students, involveds, coInvolveds } = this.getStudentsAndInvolveds()
      const body = {
        project: {
          id: this.projectInfo.id,
          payment_approved: this.checkBoxPayment,
          atendente: this.atendente,
          observation_accreditation: this.observations
        },
        students: [...students],
        involveds: [...involveds, ...coInvolveds]
      }
      await this.$http.patch('/check-in', body)
    },
    validateAdvisorsCheckbox(item) {
      if (!item.will_pay) {
        item.meal = 0
        if (item.mealValue > 0) {
          item.finalValue -= item.mealValue
          item.mealValue = 0
        }
      }
    },
    calculeForFirstTime() {
      if (this.mealOption === 0) {
        this.studentsList.forEach((student, index) => {
          if (student.meal === 0) {
            this.calculateFinalValue(this.mealOption, index, student.role)
          }
        })
        this.nameAdvisor.forEach((advisor, index) => {
          if (advisor.meal === 0) {
            this.calculateFinalValue(this.mealOption, index, advisor.type)
          }
        })
        this.coadvisorList.forEach((coadvisor, index) => {
          if (coadvisor.meal === 0) {
            this.calculateFinalValue(this.mealOption, index, coadvisor.type)
          }
        })
      }
    },
    verifyTotal() {
      if (this.subtotal < this.discount) {
        this.errors.push('discount')
        this.textWarning = this.$t('discountPayment')
        return false
      }
      return false
    },
    async changeStatus(id) {
      await this.$http.patch(`/status/project/${ this.projectInfo.id }`, {
        status_id: id
      })
      await this.$http.patch(`/status/project/${ this.projectInfo.id }`, {
        internal_status_id: id
      })
    },
    pagePrint(id, role) {
      const checkRole = role || 'estudante'
      this.$router.push(`/print-etiquetas/${ id }/${ checkRole }`)
    },
    newName(fullName) {
      const fullNameArray = fullName.split(' ')
      const newFullName = fullNameArray.filter((name) => name !== '')
      const newName = `${ newFullName.shift() } ${ newFullName.pop() ?? '' }`
      return newName
    },
    paymentType(dbType) {
      const type = {
        credenciamento: 'Credenciamento',
        'mercado pago': 'Mercado Pago',
        'nota empenho': 'Nota de Empenho'
      }
      return type[dbType]
    }
  },
  computed: {
    total() {
      const participants = [
        ...this.studentsList,
        ...this.nameAdvisor,
        ...this.coadvisorList
      ]
      const total = participants.reduce(
        (accumulator, { finalValue, will_pay: willPay, meal }) => {
          if (willPay || meal) {
            return accumulator + finalValue
          }
          return accumulator
        },
        0
      )
      return this.discount > total ? 0 : total - this.discount
    },
    subtotal() {
      const participants = [
        ...this.studentsList,
        ...this.nameAdvisor,
        ...this.coadvisorList
      ]
      const subTotal = participants.reduce(
        (accumulator, { finalValue, will_pay: willPay, meal }) => {
          if (willPay || meal) {
            return accumulator + finalValue
          }
          return accumulator
        },
        0
      )
      return subTotal
    }
  }
}
</script>
<style lang="scss" scoped>
.vinfo{
  color:#491010;
  font-weight: 700;
  background-color:#cdcbcb;
}
.modal {
  /* Estilize seu modal aqui */
}
.modal-content {
  /* Estilize o conteúdo do modal aqui */
  background-color: antiquewhite; 
  border: 1px solid gray;
  border-radius: 10px;
  padding:10px;
}
.inputName {
  background-color: #FFF;
  width:500px;
  border-radius: 5px;
  border: 1px outset gray;
  padding:5px;
}
.editNameButton {
  /*
  background-color: rgb(187, 228, 240);
  border-radius: 5px;
  border: 1px outset gray;
  margin-left:5px;
  padding-left:5px;
  padding-right:5px;
  */
  background: none;
  border: none;
  padding-left: 5px;
  cursor: pointer;  

}
.saveButton {
  background-color: #578dd2;
  border-radius: 5px;
  border: 1px outset gray;
  margin-left:5px;
  padding:5px;
}
.cancelButton {
  background-color: #bdc866;
  border-radius: 5px;
  border: 1px outset gray;
  margin-left:5px;
  padding:5px;
}
.title-payment {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 160%;
  color: #26768d;
}
.title-project {
  margin: 20px 0 50px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  color: #26768d;
}
.container-content {
  padding: 30px 0;
}
.select-type {
  max-width: 236px;
  margin-bottom: 30px;
}
.container-header {
  display: flex;
  justify-content: space-between;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 160%;
    color: #2d2d2d;
  }
}
.subtitle {
  margin-top: 30px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2d2d2d;
}
.container-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
label {
  display: flex;
  align-items: flex-start;
  font-size: 16px !important;
  cursor: pointer;
}
input[type="checkbox"] {
  margin-top: 3px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 4px;
  background: white;
}
input[type="checkbox"]:checked:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #26768d;
  background-color: #26768d;
  border-radius: 3px;
}
input[type="checkbox"]:checked:after {
  content: "";
  width: 6px;
  height: 12px;
  border: 2px solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.color-text {
  color: #135273;
  font-weight: bold;
}
.size-name {
  width: 330px;
}
.size-rate {
  width: 100px;
}
.size-meal {
  width: 220px;
  margin-bottom: 10px;
}
.input-meal {
  border: 1px solid #afaeae;
  max-width: 100%;
  border-radius: 10px;
  font-weight: 500;
  padding: 6px 12px;
}
.size-final {
  width: 120px;
}
.size-attend {
  width: 110px;
  display: flex;
  justify-content: center;
}
.size-printer {
  width: 110px;
}
.advisors {
  margin-bottom: 10px;
}
.coadvisors {
  margin-bottom: 20px;
}
.type-payment {
  display: flex;
  padding-right: 4.4%;
  justify-content: space-between;
}
.title-type-payment {
  margin-top: 40px;
  font-weight: bold;
  font-size: 20px;
}
.total-payment {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 74px;
}
.subtotal-payment {
  gap: 44px;
}
.button-save {
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
}
.custom-radio input {
  display: none;
}
.custom-radio input[type="radio"] {
  appearance: none;
  margin-top: 3px;
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #636b6f;
}
.custom-radio input + label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #636b6f;
  border-radius: 100px;
  background: white;
}
.custom-radio input:checked + label:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #26768d;
  background-color: #26768d;
  box-sizing: border-box;
}
.obs {
  margin-bottom: 20px;
}
.discount-container {
  display: flex;
  justify-content: flex-end;
}
.discount-content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-top: 20px;
}

.container-values {
  margin-top: 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.values-finals {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container-others {
  flex: 1;
}
.currency-input {
  width: 180px;
  padding: 4px 8px;
  border: 1px solid #cdcbcb;
  border-radius: 8px;
}
.checkbox-payment {
  display: flex;
  margin-top: 1%;
  margin-bottom: 1%;
}
.status-style {
  margin-right: 1%;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2d2d2d;
}

.qrcodeContent {
  display: flex;
  max-width: 300px;
  width: 100%;
  height: 120px;
  justify-content: space-between;
  padding: 5px 3px;
  gap: 5px;
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

.left-side-print {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 100px;
  // width: 100%;
  justify-content: space-between;
}

.right-side-print {
  // padding: 10px 0 !important;
  display: flex !important;
  flex-direction: column;
  text-align: right;
}
</style>
