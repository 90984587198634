<template>
  <div>
    <RegistrationSituation
      :title="cIdPlusTitle || $t('sketch')"
      :cause="getSelectedProjectDenyReason()"
      :slug="getSelectedProjectStatusSlug()"
      :statusText="statusDictionary[getSelectedProjectStatusSlug()].name"
      :theme="statusDictionary[getSelectedProjectStatusSlug()].theme"
      :fair_name="selectedProject.fair && selectedProject.fair.fair_name"
    />
    <div
      v-if="selectedProject.internal_status_id === 4"
      class="cho-container button-mercado-pago"
      label="Pagar"
    >
    </div>
  </div>
</template>
<script>
import RegistrationSituation from '@/components/organisms/Infos/RegistrationSituation'
import { mapGetters } from 'vuex'
import { statusDictionary } from '@/utils/dictionaries'

export default {
  name: 'SubscriptionRequest',
  components: { RegistrationSituation },
  data() {
    return {
      dataReady: false,
      statusDictionary,
      mercadoPago_id: null,
      mercadoPago_aproved: false
    }
  },
  computed: {
    ...mapGetters({ selectedProject: 'getSelectedProject' }),
    cIdPlusTitle() {
      let plusTitle = ''
      if (this.selectedProject.project_id && this.selectedProject.project_title) {
        plusTitle = `#${ this.selectedProject.project_id } - ${ this.selectedProject.project_title }`
      }
      return plusTitle
    }
  },
  beforeCreate() {
    const mercadoPagoScript = document.createElement('script')
    mercadoPagoScript.onload = async () => {
      const mercadoPago = new window.MercadoPago(
        'TEST-a3b8d43a-f115-4a43-92e1-c8bcaff3a98e'
      )
      mercadoPago.checkout({
        preference: {
          id: await this.getIdMercadoPago()
        },
        render: {
          container: '.cho-container',
          label: 'Pagar com Mercado Pago'
        }
      })
    }
    mercadoPagoScript.async = true
    mercadoPagoScript.setAttribute(
      'src',
      'https://sdk.mercadopago.com/js/v2'
    )
    document.body.appendChild(mercadoPagoScript)
  },
  watch: {
    mercadoPago_aproved() {
      if (this.getSelectedProjectStatusSlug() === 'aguardando-pagamento' && this.mercadoPago_aproved === true) {
        window.location.reload()
      }
    }

  },
  methods: {
    getSelectedProjectStatusSlug() {
      return this.selectedProject?.status?.slug
      || this.selectedProject?.statusSlug
    },
    getSelectedProjectDenyReason() {
      if (this.getSelectedProjectStatusSlug() === 'reprovado') {
        return this.selectedProject?.fair_justification
      }
      return this.selectedProject?.project_feedback
    },
    async getIdMercadoPago() {
      const mercadoPagoId = await this.$http.get(
        `/mercadopago/${ this.selectedProject.id }`
      )
      this.selectedProject.internal_status_id = mercadoPagoId.data === 'approved' ? 5 : this.selectedProject.internal_status.id
      this.mercadoPago_aproved = mercadoPagoId.data === 'approved'
      return mercadoPagoId.data
    }
  }
}
</script>
<style lang="scss" scoped>
.button-mercado-pago {
  display: flex;
  justify-content: flex-end;
  font-size: 1.2em;
  height: 3rem;
  margin: 0 5% 2% 0
}
</style>
