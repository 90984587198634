<template>
  <div class="denied">
    <div class="box">ACESSO NEGADO</div>
  </div>
</template>
<script>
export default {
  name: 'access-denied'
}
</script>
<style lang="scss" scoped>
.denied {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  background-color: $primary;
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    width: 80%;
    height: 80%;
    background-color: rgba(0,0,0,0.5);
  }
}
</style>
