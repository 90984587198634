<template>
  <div class="container-app-accreditation">
    <div class="container-header">
      <HeaderDefault />
    </div>
    <div class="container-title-btn">
      <h1 class="title-accreditation">Projetos</h1>
      <button @click="() => pagePrintAll()" class="btn-print">
        <img src="@/assets/printer.png"/>
        <span>imprimir todas etiquetas</span>
      </button>
    </div>
    <div class="container-search">
      <input-default
        type="text"
        v-model="filterBy"
        :placeholder="this.$t('placeholderSearchBar')" 
        :width="1300"
      />
    </div>

    <div class="container-cards">
      <div v-for="(item, index) in allProjects" :key="index">
        <div class="card-project"  v-if="[4, 5].includes(item.status_id)" :key="index" @click="detailItem(item.id)">
          <h3>{{item.project_title.toLocaleLowerCase()}}</h3>
          <div class="container-details" style="padding-left: 20px;"> 
            <span>Inscrição: <span class="projectId">{{`${item.project_id}`}}</span></span>
            <span>Status: <span :class="getStatusClass(item.status)">{{`${item.status}`}}</span></span>
            <span>Responsável: <span class="responsible">{{`${item.responsible.toLocaleLowerCase()}`}}</span></span>
          </div>
          <div class="container-details" style="padding-left: 20px;"> 
            <!-- {{ item }}-->
          </div>
        </div>
        <div v-else  class="card-project-not-clickable" :key="index" >
          <h3>{{item.project_title.toLocaleLowerCase()}}</h3>
          <div class="container-details" style="padding-left: 20px;">
            <span>Inscrição: <span class="projectId">{{`${item.project_id}`}}</span></span>
            <span>Status: <span :class="getStatusClass(item.status)">{{`${item.status}`}}</span></span>
            <span>Responsável: <span class="responsible">{{`${item.responsible.toLocaleLowerCase()}`}}</span></span>
          </div>
          <div class="container-details" style="padding-left: 20px;"> 
            <span :class="getStatusClass('11')">Atenção: direcione esta inscrição para a secretaria da Mostratec.</span>
          </div>
          <div class="container-details" style="padding-left: 20px;"> 
            <!-- {{ item }}-->
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <v-container>
        <v-row justify="end">
          <v-pagination
            v-model="page"
            class="my-4"
            :length="lastPage"
          >
          </v-pagination>
        </v-row>
        <v-row
          class="mr-0"
          justify="end"
        >
          <span class="pagination__text">{{ renderPagination() }}</span>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import HeaderDefault from '../organisms/Headers/HeaderDefault'
import InputDefault from '../atoms/Inputs/InputDefault'

export default {
  name: 'AccreditationTemplate',
  components: {
    HeaderDefault,
    InputDefault
  },
  data() {
    return {
      filterBy: '',
      allProjects: [],
      page: 1,
      perPage: 50,
      total: 0,
      lastPage: 1
    }
  },
  watch: {
    async filterBy() {
      await this.getData()
    },
    async page() {
      await this.getData()
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const { data } = await this.$http.get(`/check-in?filter=${ this.filterBy }&page=${ this.page }&limit=${ this.perPage }`)
      this.allProjects = data.data
      this.total = data.total
      this.lastPage = data.lastPage
    },
    detailItem(id) {
      this.$router.push(`project-details-accreditation/${ id }`)
    },
    renderPagination() {
      const current = this.page === 1 ? 1 : (this.page - 1) * this.perPage + 1
      const results = this.page * this.perPage
      if (this.total <= 15) {
        this.page = 1
      }
      return `${ current } - ${ results } ${ this.$t('outOf') } ${ this.total }`
    },
    pagePrintAll() {
      this.$router.push('print-todas-etiquetas/')
    },
    getStatusClass(status) {
      if (status == 'a') return 'green'
      switch (status) {
        case 'Aguardando aceite da Feira': return 'statusOrange'
        case 'Aguardando pagamento': return 'statusYellow'
        case 'Aguardando revisão': return 'statusOrange'
        case 'Em revisão': return 'statusOrange'
        case 'Inscrição efetivada': return 'statusGreen'
        case 'Revisão Negada': return 'statusRed'
        case '12': return 'statusOrange'
        case '4': return 'statusYellow'
        case '2': return 'statusOrange'
        case '3': return 'statusOrange'
        case '5': return 'statusGreen'
        case '11': return 'statusRed'
        default: return 'statusOrange'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-app-accreditation{
  background-color: #f1f5f6;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 60px 60px;
  font-family: "Mulish", sans-serif;
}

.container-header{
  margin: 0 0 70px 0;
}

.container-title-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-print{
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #26768D;
  padding: 8px 10px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #26768D;
  text-transform: uppercase;
}

.title-accreditation{
  font-size: 24px;
  font-weight: bold;
  color: #26768D;
}

.container-search{
  margin: 45px 0 32px 0;
}

.container-cards{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card-project{
  padding: 24px 46px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);;
  cursor: pointer;

  h3{
    font-weight: bold;
    color: #135273;
    text-transform: capitalize;
  }

  span{

    span{
      font-weight: bold;
      color: #570c05;
      text-transform: capitalize;
      border-color: transparent;
      border-radius: 8px;
      border-width: 0px;
      padding: 3px 8px 3px 8px;
      text-align: center;
    }
  }
}


.card-project-not-clickable{
  padding: 24px 46px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);;

  h3{
    font-weight: bold;
    color: #135273;
    text-transform: capitalize;
  }

  span{

    span{
      font-weight: bold;
      color: #570c05;
      text-transform: capitalize;
      border-color: transparent;
      border-radius: 8px;
      border-width: 0px;
      padding: 3px 8px 3px 8px;
      text-align: center;
    }
  }
}

span.statusYellow{
    background-color: #f2f9bf;
    display: inline-block;
    min-width: 190px;
}
span.statusOrange{
    background-color: #fbe1c1;
    display: inline-block;
    min-width: 190px;
}
span.statusGreen{
    background-color: #d2fdd1;
    display: inline-block;
    min-width: 190px;
}
span.statusRed{
    background-color: #fdc7c7;
    display: inline-block;
    min-width: 190px;
}

span.projectId{
    background-color: #f0f0f0;
    display: inline-block;
    min-width: 50px;
}
span.responsible{
    background-color: #f0f0f0;
    display: inline-block;
    min-width: 150px;
}

.container-details{
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  gap: 24px;
}

::v-deep .v-pagination__item {
  box-shadow: none !important;
  color: #b5b5b5 !important;
  font-size: 14px !important;
  height: 30px !important;
  line-height: 16px !important;
  min-width: 15px !important;
}
</style>
