<template>
  <div class="container-app-details">
    <HeaderDefault />
    <div>
      <h1 class="title-project" style="text-align: center; font-size: 30px; text-decoration: underline;">Credenciamento</h1>
      <div @click="back" class="return">
        <v-icon medium color="#0E5671">mdi-arrow-left</v-icon>
        <h3>Retornar à listagem</h3>
      </div>
    </div>
    <h2 class="title-project"><span class="projectInfo" style="width: 100%; display: inline-block;">{{`${projectInfo.project_id} - ${projectInfo.project_title}`}}</span></h2>
    <h4 class="project-info">Nível: <span>{{getLevelDescription()}}</span></h4>
    <h4 v-if="projectInfo.booth" class="project-info">Estande: <span class="projectInfo">{{`${projectInfo.booth}`}}</span>
      Status: <span :class="getStatusClass(projectInfo.internal_status.name)">{{`${projectInfo.internal_status.name}`}}</span>
    </h4>
    <h4 v-else class="project-info">Estande: <span>{{`Não informado`}}</span>
      Status: <span :class="getStatusClass(projectInfo.internal_status.name)">{{`${projectInfo.internal_status.name}`}}</span>
    </h4>
    <div>
      <FormAccreditationPayment
        v-if="Object.values(projectInfo).length"
        :projectInfo="projectInfo"
      />
    </div>
  </div>
</template>

<script>
import FormAccreditationPayment from './FormAccreditationPayment'
import HeaderDefault from '../components/organisms/Headers/HeaderDefault'

export default {
  name: 'AccreditationProjectDetails',
  components: {
    FormAccreditationPayment,
    HeaderDefault
  },
  data() {
    return {
      projectInfo: {}
    }
  },
  created() {
    this.getInfos()
  },
  methods: {
    async getInfos() {
      const { data: response } = await this.$http.get(`/project/${ this.$route.params.id }`)
      this.projectInfo = response
      this.validationProjectTitleLength(response.project_title)
    },
    getLevelDescription(){
      switch(this.projectInfo.level){
        case 'grade' :          return 'Educação Infantil - pré-escola (estudantes de 4 a 6 anos)'
        case 'middle_cat_1' :   return 'Ensino Fundamental - Categoria 1 (1º ao 3º ano)'
        case 'middle_cat_2' :   return 'Ensino Fundamental - Categoria 2 (4º ao 6º ano)'
        case 'middle_cat_3' :   return 'Ensino Fundamental - Categoria 3 (7º ao 9º ano)'
        case 'middle_cat_eja' : return 'nsino Fundamental - EJA'
        case 'high' :           return 'Ensino Médio/Técnico'
        default : return 'unknown'
      }


    },
    async back() {
      this.$router.push('/accreditation')
    },
    validationProjectTitleLength(title) {
      if (title.length > 50) {
        this.projectInfo.project_title = `${ title.split(' ').slice(0, 10).join(' ') } ...`
      }
    },
    getStatusClass(status) {
      if (status == 'a') return 'green'
      switch (status) {
        case 'Aguardando aceite da Feira': return 'statusOrange'
        case 'Aguardando pagamento': return 'statusYellow'
        case 'Aguardando revisão': return 'statusOrange'
        case 'Em revisão': return 'statusOrange'
        case 'Inscrição efetivada': return 'statusGreen'
        case 'Revisão Negada': return 'statusRed'
        default: return 'statusOrange'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-app-details{
  background-color: #f1f5f6;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 15px 15px;
  font-family: "Mulish", sans-serif;
}
.return {
  display: flex;
  max-width: 220px;
  width: 100%;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #0e5671;
  margin: 30px 0 15px;
  line-height: 22.59px;
  height: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  h3 {
    color: $primaryDark;
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    margin-left: 5px;
  }
}

.title-project{
  color: #26768D;
  font-size: 24px;
  font-weight: bold;
  //margin-bottom: 1%;
  //margin-top: 1%;
  padding: 15px 15px 15px;
}

.project-info{
  color: #26768D;
  font-size: 18px;
  font-weight: bold;
  padding-left: 15px;
}

h4{
  span{
    font-weight: bold;
    color: #570c05;
    text-transform: capitalize;
    border-color: transparent;
    border-radius: 8px;
    border-width: 0px;
    padding: 3px 8px 3px 8px;
  }
}

.projectId{
  font-size: 140%;
}

span.statusYellow{
    background-color: #f2f9bf;
}
span.statusOrange{
    background-color: #fbe1c1;
}
span.statusGreen{
    background-color: #d2fdd1;
}
span.statusRed{
    background-color: #fdc7c7;
}
span.projectInfo{
    background-color: #ffffff;
    display: inline-block;
    min-width: 50px;
    border-color: transparent;
    border-radius: 8px;
    border-width: 0px;
    padding: 3px 8px 3px 8px;
}

</style>
